var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{ref:"ServersTable",staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.isLoading,"loading-text":_vm.$t('misc.LoadText'),"headers":_vm.selectedHeaders,"items":_vm.servers,"sort-by":['id']},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.$t("MyServers.Title"))+" "),_c('v-spacer'),(_vm.user.id != null)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.allServers = !_vm.allServers}}},[(_vm.displayAllServerText)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("MyServers.ShowMyServers"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("MyServers.ShowPublicServers"))+" ")])]):_vm._e(),(_vm.user.id != null)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.newDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("MyServers.New"))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.rcon_password",fn:function(ref){
var item = ref.item;
return [(item.rcon_password != null)?_c('v-text-field',{attrs:{"append-icon":item.showRcon ? 'mdi-eye' : 'mdi-eye-off',"type":item.showRcon ? 'text' : 'password',"readonly":""},on:{"click:append":function($event){item.showRcon = !item.showRcon}},model:{value:(item.rcon_password),callback:function ($$v) {_vm.$set(item, "rcon_password", $$v)},expression:"item.rcon_password"}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/user/' + item.user_id }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.public_server",fn:function(ref){
var item = ref.item;
return [(item.public_server == 1)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(
          _vm.user.id != null && (_vm.IsAnyAdmin(_vm.user) || item.user_id == _vm.user.id)
        )?_c('v-btn',{attrs:{"small":"","color":item.colour,"loading":item.isLoading,"disabled":item.isLoading},on:{"click":function($event){return _vm.checkStatus(item)}}},[_vm._v(" "+_vm._s(_vm.$t("MyServers.Status"))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(
          _vm.user.id != null && (_vm.IsAnyAdmin(_vm.user) || item.user_id == _vm.user.id)
        )?_c('div',[_c('v-icon',{on:{"click":function($event){return _vm.deleteSelectedServer(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{on:{"click":function($event){return _vm.editSelectedServer(item)}}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}},{key:"item.flag",fn:function(ref){
        var item = ref.item;
return [_c('img',{staticStyle:{"border-radius":"5px"},attrs:{"src":_vm.get_flag_link(item)}})]}}])}),_c('v-bottom-sheet',{attrs:{"inset":"","persistent":""},model:{value:(_vm.responseSheet),callback:function ($$v) {_vm.responseSheet=$$v},expression:"responseSheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"200px"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"text":"","color":"success"},on:{"click":function($event){_vm.responseSheet = !_vm.responseSheet;
          _vm.response = '';}}},[_vm._v(" "+_vm._s(_vm.$t("misc.Close"))+" ")]),_c('div',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.response)+" ")])],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("MyServers.DeleteConfirmation"))+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("misc.No"))+" ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteServerConfirm()}}},[_vm._v(" "+_vm._s(_vm.$t("misc.Yes"))+" ")])],1)],1)],1),_c('ServerDialog',{attrs:{"serverInfo":_vm.newServer,"title":_vm.formTitle},on:{"is-new-server":_vm.GetServers},model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }